import Vue from 'vue'
import Vuex from 'vuex'

//  import des modules vuex
import auth from './modules/auth'
import messages from './modules/messages'
import application from './modules/application'
import annuaire from './modules/annuaire'
import manoeuvre from './modules/manoeuvre'
import centre from './modules/centre'
import echo from './modules/echo'
import fmpa from './modules/fmpa'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules: {
        strict: debug,
        messages: messages,
        annuaire: annuaire,
        auth: auth,
        application: application,
        manoeuvre: manoeuvre,
        centre: centre,
        fmpa: fmpa,
        echo: echo
    }
})
