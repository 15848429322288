import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';
import colors from 'vuetify/es5/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
  	theme: {
		themes: {
			light: {
				primary: colors.blueGrey.darken1,
				secondary: colors.blueGrey.lighten4,
				accent: colors.orange.accent2,
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107'
			},
		},
  	},
    lang: {
      	locales: { fr },
      	current: 'fr',
    },
  	icons: {
    	iconfont: 'mdi',
  	},
});
