//  Module Vuex de l'application
import axios from 'axios'

const state = {
    messages_membre: {
        entrant: [],
        sortant: []
    },
    messages_non_lus: []
}

const actions = {
    getMessagesMembre(context, payload) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/user/messages_membre/'  + payload)
                .then((response) => {
                    
                    context.commit('majMessagesMembre', {messages_in: response.data.messages_in, messages_out: response.data.messages_out})
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    },
    getMessagesNonLus(context, payload) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/user/messages_non_lus/'  + payload)
                .then((response) => {
                    
                    context.commit('majMessagesNonLus', response.data.messages)
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    }

    
}

const mutations = {
    majMessagesMembre(state, payload) {
        state.messages_membre.entrant = payload.messages_in
        state.messages_membre.sortant = payload.messages_out
    },
    majMessagesNonLus(state, payload) {
        state.messages_non_lus = payload
    }
}

const getters = {
    messagesMembre(state) {
        return state.messages_membre
    },
    messagesNonLus(state) {
        return state.messages_non_lus
    }
}

export default{
    state,
    actions,
    mutations,
    getters
}