<template>
    <v-snackbar 
        v-model="show"
        :color="couleur"
        :timeout="timeout"
    >
        {{message}}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="show = false">OK</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        data() {
            return {
                show: false,    // snackbar masquée par défaut
                message: '',
                couleur: 'info',
                timeout: 4000
            }
        },
        created(){
            this.$store.watch(state => state.application.snackbar.message, () =>{
                const message = this.$store.state.application.snackbar.message;
                const type = this.$store.state.application.snackbar.type;
                                                                
                if(message !=''){
                    this.message = message;
                    this.couleur = type;
                    this.show = true;

                    //on remet un message vide
                    var param = {
                        type: 'info',
                        message: ''
                    }
                    this.$store.commit('afficheSnackbar', param);
                } 
            }) 
        }
    }
</script>
