<template>
	<v-app>
		<v-navigation-drawer app width="260" :mini-variant="miniVariant" clipped v-model="drawer" v-if="masquageToolbar">
            <v-list nav class="primary--text" dense>
                <v-list-item two-line to="/compte">
                    <v-list-item-avatar color="blue-grey lighten-3">
                        <img v-if="utilisateurCourant && utilisateurCourant.photo" :src="getPhoto(utilisateurCourant.photo)">
                        <span v-else class="white--text text-h6 font-weight-light">{{utilisateurCourant != null ? premiereLettreMaj(utilisateurCourant.prenom)+premiereLettreMaj(utilisateurCourant.nom): ''}}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{nomUtilisateur}}</v-list-item-title>
                        <v-list-item-subtitle>{{centreUtilisateur}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <template v-for="menu in menus">

                    <!-- si présence de sous-menu -->
                    <v-list-group v-if="menu.children && acces_autorise(menu.permissions)" :prepend-icon="menu.icone" :key="menu.nom">
                        <template v-slot:activator>
                            <v-list-item-title>{{menu.nom}}</v-list-item-title>
                        </template>
                        <!-- <v-list-item v-for="(sous_menu, index) in menu.children" :key="index" :to="sous_menu.lien" v-if="acces_autorise(sous_menu.roles, sous_menu.permissions)"> -->
                        <v-list-item v-for="(sous_menu, index) in sous_menus(menu.children)" :key="index" :to="sous_menu.lien">
                            <v-list-item-icon>
                                <v-icon>{{sous_menu.icone}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{sous_menu.nom}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <!-- si pas de sous-menu -->
                    <v-list-item :key="menu.nom" :to="menu.lien" v-else-if="acces_autorise(menu.permissions)">
                        <v-list-item-icon>
                            <v-icon>{{menu.icone}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{menu.nom}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <template v-slot:append>
                <v-row class="ma-0 mb-1" justify="center">
                    <div class="primary--text body-2 text-decoration-underline mx-1" style="cursor: pointer" @click="onClickPolitiqueConfidentialite">Mentions légales</div>
                </v-row>
                <v-row class="ma-0 mb-1" justify="center">
                    <div class="caption">v{{$store.getters.appVersion}}</div>
                </v-row>
                <div class="pa-2">
                    <v-btn depressed color="secondary" class="primary--text" block @click.prevent="logout">
                        <v-icon class="mr-2">mdi-logout</v-icon>
                        Déconnexion
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
		<v-app-bar app dark color="primary" clipped-left clipped-right v-if="masquageToolbar" :elevate-on-scroll="affichagePortable" :shrink-on-scroll="affichagePortable" fade-img-on-scroll :height="affichagePortable ? 35 : 40" dense>
            <!-- <template v-slot:img="{ props }">
                <v-img v-bind="props"
                    gradient="to top right, rgba(55,236,186,.7), rgba(25,32,72,.7)"
                ></v-img>
            </template> -->
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <!-- <v-toolbar-title class="white--text">
                <span :class="classeTitreApp" class="font-weight-light">SP Planning</span>
            </v-toolbar-title> -->
            
            <v-toolbar-title>
                SP Planning
                <span class="text-caption text--lighten-3 ml-3" v-if="utilisateurCourant != null && utilisateurCourant.membres_centre.length > 1">{{ centreUtilisateur }}</span>
            </v-toolbar-title>
            
            <v-spacer></v-spacer>
            <div v-if="estVersionDev">
                <v-chip class="my-2" color="red darken-3" text-color="white" label>
                    {{ affichagePortable ? 'dev' : 'version développement'}}
                </v-chip>
            </div>
            <!-- <template v-if="utilisateurCourant != null && utilisateurCourant.membres_centre.length > 1 && affichagePortable">
                <div>
                    <h4 class="caption text--lighten-3 mt-3">{{ centreUtilisateur }}</h4>
                </div>
            </template> -->
            <v-btn icon @click="onClickIconeMessages">
                <v-badge :content="nombreMessages" :value="nombreMessages" overlap color="red darken-3" class="mt-3 mr-2">
                    <v-icon :large="!affichagePortable">mdi-email</v-icon>
                </v-badge>
            </v-btn>
            <v-menu bottom left v-if="utilisateurCourant != null && utilisateurCourant.membres_centre.length > 1">
                <template v-slot:activator="{ on }">
                    <v-btn dark icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-subheader>Choix du profil</v-subheader>
                    <v-list-item v-for="(item, i) in liste_cs" :key="i" @click="onClickChangeProfil(item)" :disabled="!item.estActif">
                        <v-list-item-title>{{ item.centre.nom }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <template v-slot:extension>
                <v-slide-x-transition mode="out-in">
                    <div class="flex-grow-1 font-weight-light" :class="affichagePortable ? 'text-right' : 'text-center text-h5'" :key="titre_page">{{ titre_page }}</div>
                </v-slide-x-transition>
            </template>
        </v-app-bar>

		<v-main>
			<transition name="anim-pages" mode="out-in">
				<router-view/>
			</transition>
		</v-main>
		<Snackbar/>

        <!-- dialogue changement du mot de passe -->
        <v-dialog v-model="openDialogModifPassword" persistent max-width="500px">
            <v-card>
                <v-toolbar dark dense flat color="primary">
                    <v-toolbar-title>
                        <v-card-title>Modifier le mot de passe</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-1">
                    <v-form>
                        <v-text-field label="Nouveau mot de passe" type="password" hint="minimum 6 caractères" v-model.trim="$v.formulaireUpdatePassword.nouveau_pass.$model" :error-messages="erreursPasswordMaj"></v-text-field>
                        <v-text-field label="Confirmation du nouveau mot de passe" type="password" v-model.trim="$v.formulaireUpdatePassword.nouveau_pass_confirmation.$model" :error-messages="erreursPasswordConfirm"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="updatePassword" :loading="chargementModifPassword" :disabled="estFormulaireModifPasswordValid">OK</v-btn>  
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- drawer message pour la version pc -->
        <v-navigation-drawer app v-if="!affichagePortable" width="400" right absolute temporary v-model="openMessageDrawer">
            <v-row class="ma-0">
                <v-col class="pa-0">
					<v-toolbar dark flat dense color="deep-purple lighten-1">
						<v-btn small icon class="ml-0" @click="openMessageDrawer = !openMessageDrawer">
							<v-icon>mdi-arrow-right</v-icon>
						</v-btn>
						<v-toolbar-title>Messages</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-chip small class="ma-2 mr-0" color="deep-purple lighten-3">{{ nombreMessages }}</v-chip>
					</v-toolbar>
                

                    <div id="liste_messages_drawer" v-if="messagesNonLus.length > 0">
                        <v-list class="pa-0">
                            <v-slide-x-transition group>
                                <template v-for="(message, index) in messagesNonLus">
                                    <v-divider v-if="index > 0" :key="index"></v-divider>
                                    <v-list-item :key="message.id" :style="'background-color: '+getIconCouleur(message.type).couleur">
                                        <v-list-item-avatar tile>
                                            <v-icon size="40">{{getIconCouleur(message.type).icone}}</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="pa-0 pb-2">
                                            <v-row class="ma-0">
                                                <v-col class="pa-0">
                                                    <v-row class="ma-0">
                                                        <div class="text-overline deep-purple--text">{{formatDate(message.created_at)}}</div>
                                                        <v-spacer/>
                                                        <v-btn icon x-small @click="onClickSupprMessage(message.id)" class="mt-2" color="grey">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-row>
                                                    <div class="font-weight-bold text-subtitle-1">{{message.data.titre}}</div>
                                                    <div class="font-weight-light text-subtitle-2">{{message.data.message}}</div>
                                                    
                                                </v-col>
                                            </v-row>
                                            <!-- <v-list-item-title v-html="message.data.titre"></v-list-item-title>
                                            <v-list-item-subtitle v-html="message.data.message"></v-list-item-subtitle> -->
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-slide-x-transition>
                        </v-list>
                    </div>
                    <v-row v-else class="ma-0 mt-12" justify="center">
                        <div class="font-italic">Aucun message</div>
                    </v-row>
                </v-col>
            </v-row>
            
        </v-navigation-drawer>     
  	</v-app>
</template>

<script>
import axios from 'axios'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import Snackbar from './components/Snackbar'
import moment from 'moment'
moment.locale('fr')
export default {
  	name: 'App',
  	components:{
        Snackbar,
	},
	created() {
        
		//	on n'affiche pas le menu au démarrage sur les téléphones
		if (this.$vuetify.breakpoint.name != 'xs') {
			this.drawer = true
        }

        //  on vérifie que les notifications sont supportées par l'appareil
        if('Notification' in window && 'serviceWorker' in navigator) {
            this.notificationSupportees = true
        }

        //  on vérifie si les notifications sont autorisees ou non au démarrage de la page
        Notification.requestPermission().then( resultat => {
            this.notificationsActivees = resultat === 'granted'
        })

        this.$store.dispatch('initMembreCourant')
            .then(() => {
                this.$store.dispatch('getMessagesNonLus', this.$store.getters.membreCourant.id_user)
            })
        
    },
    mounted() {
        //  écoute des messages envoyés par le service worker
        navigator.serviceWorker.addEventListener("message", this.receptionEvenement)
        
        //  en rechargeant la page, et si l'utilisateur Courant existe dans le local Storage, on se connecte au Websocket
        const utilisateur = !localStorage.getItem('utilisateur') ? null : JSON.parse(localStorage.getItem('utilisateur'))
        if(utilisateur) {
            this.$store.commit('connexionEcho', { token: utilisateur.token, id: utilisateur.id})
            if(this.acces_autorise(['superAdmin'])) this.$store.commit('connexionOnline')
            
        } 

        
        
    },
    beforeDestroy() {
        //  fin des écoutes des messages envoyés par le service worker
        navigator.serviceWorker.removeEventListener("message", this.receptionEvenement)
    },
  	data: () => ({
   		miniVariant: false,
        drawer: false,
        formulaireUpdatePassword: {
            nouveau_pass: '',
            nouveau_pass_confirmation: ''                    
        },
		chargement_connexion: false,
        chargementModifPassword: false,
        menus: [
            // {
            //     lien: '/messages',
            //     icone: 'mdi-view-dashboard',
            //     nom: 'Messages',
            //     permissions: []    // pour la visibilite du lien
            // },
            {
                lien: '/planning_spv',
                icone: 'mdi-calendar-multiselect',
                nom: 'Planning',
                permissions: ['acces planning spv']
            },
            {
                lien: '/manoeuvres',
                icone: 'mdi-arm-flex',
                nom: 'Manoeuvres',
                permissions: ['acces presence manoeuvres', 'acces suivi fmpa'],
                children: [
                    {
                        lien: '/presence_manoeuvres',
                        nom: 'Présence',
                        permissions: ['acces presence manoeuvres'],
                    },
                    {
                        lien: '/suivi_fmpa',
                        nom: 'Suivi FMPA',
                        permissions: ['acces suivi fmpa'],
                    }
                ]
            },
            {
                lien: '/dispos_centre',
                icone: 'mdi-calendar-search',
                nom: 'Disponibilités centre',
                permissions: ['acces dispos centre']
            },
            {
                lien: '/annuaire',
                icone: 'mdi-notebook-multiple',
                nom: 'Annuaire',
                permissions: []
            },
            {
                icone: 'mdi-account-group',
                nom: 'Gestion centre',
                permissions: ['acces gestion astreintes manoeuvres', 'acces gestion comptes', 'acces gestion fmpa', 'acces gestion equipes', 'acces plannification gardes', 'acces statistiques'],
                children: [
                    {
                        lien: '/plannification_gardes',
                        nom: 'Plannification',
                        permissions: ['acces plannification gardes']
                    },
                    {
                        lien: '/astreintes_manoeuvres',
                        nom: 'Astreintes & manoeuvres',
                        permissions: ['acces gestion astreintes manoeuvres']
                    },
                    {
                        lien: '/gestion_fmpa',
                        nom: 'FMPA',
                        permissions: ['acces gestion fmpa']
                    },
                    {
                        lien: '/comptes_utilisateur',
                        nom: 'Comptes',
                        permissions: ['acces gestion comptes']
                    },
                    {
                        lien: '/equipes',
                        nom: 'Equipes',
                        permissions: ['acces gestion equipes']
                    },
                    {
                        lien: '/statistiques',
                        nom: 'Statistiques',
                        permissions: ['acces statistiques']
                    }
                ]
            },
            {
                icone: 'mdi-skull',
                nom: 'Administration',
                permissions: ['superAdmin', 'acces journal', 'acces gestion departement'],    // la permission "superAdmin" n'existe pas mais le rôle super admin accede à tout
                children: [
                    {
                        lien: '/gestion_departement',
                        nom: 'Gestion département',
                        permissions: ['superAdmin','acces gestion departement']
                    },
                    {
                        lien: '/online',
                        nom: 'Online',
                        permissions: ['superAdmin']
                    },
                    {
                        lien: '/logs',
                        nom: 'Journal',
                        permissions: ['acces journal']
                    },
                    {
                        lien: '/test_admin',
                        nom: 'Test admin',
                        permissions: ['superAdmin']
                    }
                ]
            },
            // {
            //     lien: '/contact',
            //     icone: 'mdi-email',
            //     nom: 'Contact',
            //     permissions: []
            // },
        ],
        notificationsActivees: false,
        notificationSupportees: false,
        openMessageDrawer: false
        		
	}),
	computed: {
        affichagePortable() {
            return this.$vuetify.breakpoint.name == 'xs'
        },
        centre_courant() {
			return this.$store.getters.membreCourant ? this.$store.getters.membreCourant.centre : ''
        },
        centreUtilisateur() {
			return this.$store.getters.membreCourant ? this.$store.getters.membreCourant.centre.nom : ''
        },
        classeTitreApp() {
            return this.affichagePortable ? 'text-h5' : 'text-h4'
        },
        erreursPasswordMaj() {
            const errors = []
            if (!this.$v.formulaireUpdatePassword.nouveau_pass.$dirty) return errors
            !this.$v.formulaireUpdatePassword.nouveau_pass.required && errors.push('Le mot de passe est obligatoire')
            return errors
        },
        erreursPasswordConfirm() {
            const errors = []
            if (!this.$v.formulaireUpdatePassword.nouveau_pass_confirmation.$dirty) return errors
            !this.$v.formulaireUpdatePassword.nouveau_pass_confirmation.required && errors.push('Le mot de passe est obligatoire')
            if(this.formulaireUpdatePassword.nouveau_pass_confirmation != this.formulaireUpdatePassword.nouveau_pass) errors.push('Les mots de passe ne correspondent pas')
            return errors
        },
        estFormulaireModifPasswordValid() {
            return this.$v.formulaireUpdatePassword.$invalid
        },
		estVersionDev() {
			return process.env.NODE_ENV !== 'production'
        },
        liste_cs() {
            return this.utilisateurCourant != null ? this.$store.getters.utilisateurCourant.membres_centre :[]
        },
		masquageToolbar(){
            return (this.$route.path==='/login' || this.$route.path==='/maintenance' || this.$route.name==='password.reset')? false : true
        },
        messagesNonLus() {
            return this.$store.getters.messagesNonLus
        },
        nombreMessages() {
            return this.messagesNonLus.length
        },
		nomUtilisateur(){
            var nom_complet = "";
            const utilisateur = this.$store.getters.utilisateurCourant;
                               
            if(utilisateur){
                nom_complet = utilisateur.prenom +' '+utilisateur.nom;
                // if(utilisateur.grade) {
                //     nom_complet = utilisateur.grade.nom + ' ' + nom_complet
                // }    
            }
            
            return nom_complet;
        },
        openDialogModifPassword: {
            //return this.formattageDate(this.formulaire_ligne.dateBesoin);
            get: function () {
                return this.$store.getters.utilisateurCourant ? this.$store.getters.utilisateurCourant.passwordTemporaire : false
            },
            set: function(valeur) {

            }
        },
        titre_page() {
            return this.$store.getters.titrePage
        },
        utilisateurCourant(){
            return this.$store.getters.utilisateurCourant
        },
       
	},
	methods: {
        acces_autorise(permissions_requises) {
            // on vérifie si l'utilisateur a les droits pour afficher le menu
            
            let permission_trouvee = false

            if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
            
                const roles_utilisateur = this.$store.getters.membreCourant.roles
                const permissions_utilisateur = this.$store.getters.membreCourant.permissions
                const permissions_centre = this.$store.getters.membreCourant.centre.parametres

                const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                

                //	si super admin on autorise tout
                if (estSuperAdmin) {
                    permission_trouvee = true
                }
                else {
                    //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                    roles_utilisateur.forEach(role => {
                        role.permissions.forEach(permission => {
                                if( permissions_requises.includes(permission.name) && permissions_centre.includes(permission.name)) {
                                    permission_trouvee = true
                                }
                            })
                    })
                

                    // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                    if (!permission_trouvee) {
                        
                        permissions_utilisateur.forEach(permission => {
                            if( permissions_requises.includes(permission.name) && permissions_centre.includes(permission.name)) {
                                permission_trouvee = true
                            }
                        })
                    }
                }
            }

            //	on autorise si pas de permission ou si une permission et que la permission est trouvée
            return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
        },
        formatDate(date) {
			if(!date) return ''
			return moment(date).format('DD/MM/YYYY HH:mm')
        },
        getIconCouleur(type) {
				// retourne une icone et une couleur selon le type de notification
				let couleur = ''
				let	icone = 'mdi-email'
				
				switch (type) {
					case 'App\\Notifications\\NotificationChangementPlanning':
						couleur = '#EEEEEE'
						icone = 'mdi-calendar-alert'
						break;
					case 'App\\Notifications\\NotificationAnnulationGarde':
						couleur = '#EF9A9A'
						icone = 'mdi-calendar-remove'
						break;
					case 'App\\Notifications\\NotificationAttributionGarde':
						couleur = '#C5E1A5'
						icone = 'mdi-calendar-plus'
						break;
					case 'App\\Notifications\\NotificationDiffusionPlanning':
						couleur = '#EEEEEE'
						icone = 'mdi-cloud'
						break;
					case 'App\\Notifications\\NotificationRappelDispos':
						couleur = '#EEEEEE'
						icone = 'mdi-alert-circle'
						break;
					case 'App\\Notifications\\NotificationRappelManoeuvre':
						couleur = '#EEEEEE'
						icone = 'mdi-alert-circle'
						break;
				
					default:
						break;
				}
				return {
					couleur: couleur,
					icone: icone
				}
		},
        getPhoto(chemin){
            return "/storage/"+chemin;
        },
        logout() {
            this.$store.commit('logout');
            this.$store.commit('deconnexionEcho')
            this.$router.push('/login');
        },
        onClickChangeProfil(membre) {
            this.$store.dispatch('modifMembreCourant',membre)
        },
        onClickIconeMessages() {
            if (this.$route.path !== '/messages' && this.affichagePortable) this.$router.push('/messages')
            else if(!this.affichagePortable) this.openMessageDrawer = true
            //this.$router.push('/dashboard')
        },
        onClickPolitiqueConfidentialite() {
            this.$router.push('/politique')
        },
        onClickSupprMessage(id_message) {
            axios.post('api/user/delete_message', { 
				id: id_message
				})
                .then((reponse) => {
                    this.$store.dispatch('getMessagesNonLus', this.$store.getters.membreCourant.id_user)
                })
                .catch((error) => {
                    
                })
                .then(() => {
                    
                })
        },
		premiereLettreMaj(text){
            return text.charAt(0).toUpperCase();
        },
        receptionEvenement(e) {
            console.log('evenement: ', e.data.detail)
            // on navigue vers la page accueil (pour voir les derniers messages)
            //this.$router.push('/')
        },
        snackbar(type,message){
            this.$store.commit('afficheSnackbar', {type: type, message: message});
        },
        sous_menus(liste_sous_menus) {
            // retourne uniquement les menus autrisés
            return liste_sous_menus.filter( sous_menu => this.acces_autorise(sous_menu.permissions))
        },
        updatePassword() {
            //  on vérifie que le formulaire est valide
            if( !this.$v.formulaireUpdatePassword.$invalid) {
                this.chargementModifPassword = true
                axios.post('api/user/change_password', { id: this.$store.getters.utilisateurCourant.id, ...this.formulaireUpdatePassword})
                    .then((reponse) => {
						this.openDialogModifPassword = false
						//  on n'affiche la snackbar que l'il y a un message à afficher
                        reponse.data.resultat && this.snackbar('success',reponse.data.resultat);
                        this.$store.commit('passParDefaut', false);
						this.$router.push({path: '/'})
                    })
                    .catch((error) => {
                        //  on n'affiche la snackbar que l'il y a un message à afficher
                        error.response.data.error && this.snackbar('error', error.response.data.error);
                    })
                    .then(() => {
                        this.chargementModifPassword = false
                        this.$v.formulaireUpdatePassword.$reset()
                		this.formulaireUpdatePassword.nouveau_pass = ''
                		this.formulaireUpdatePassword.nouveau_pass_confirmation = ''
                    })
                
            }
        },
        
    },
    validations: {
		formulaireUpdatePassword: {
            nouveau_pass: {
                required,
                minLength: minLength(6)                    
            },
            nouveau_pass_confirmation: {
                required,
                sameAsPassword: sameAs('nouveau_pass')                    
            },
        },
    },
    watch: {
		centre_courant() {
			//this.$store.dispatch('getMessagesMembre', this.$store.getters.membreCourant.id)			
		}
	}
};
</script>

<style>
.anim-pages-enter-active, .anim-pages-leave-active {
  transition: opacity 0.2s ease;
}
.anim-pages-enter, .anim-pages-leave-active {
  opacity: 0
}

/* img[alt="www.000webhost.com"] {
	opacity:0 !important;
} */

html {
    overflow-y: auto !important;
}

/* modification de l'aspect des scrollbars dans Chrome */

*::-webkit-scrollbar {
	width: 5px;
}

*::-webkit-scrollbar-track {
  background: #ddd;
}
 
*::-webkit-scrollbar-thumb {
  background: #BDBDBD; 
}

/* Modification des scrollbars our Firefox */
* {
    scrollbar-width: thin;
}

</style>

<style scoped>

#liste_messages_drawer {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
