//  Module Vuex de l'application

const state = {
    snackbar: {
        type: 'info',
        message:'',
        //affichage: false
    },
    membre_courant: null,
    packageVersion: process.env.VUE_APP_VERSION || 0,
    titre_page: ''
}

const actions = {
    // affichNotification(context, payload) {
    //     //payload:
    //     if ('serviceWorker' in navigator) {
    //         navigator.serviceWorker.ready
    //             .then(swreg => swreg.showNotification('Test de notification', {
    //                 body: 'Ceci est une notification',
    //                 icon: '/img/icons/msapplication-icon-144x144.png',
    //                 image: '',
    //                 vibrate: [300, 200, 300],
    //                 badge: '',
    //                 // actions: [
    //                 //     { action: 'confirm', title: 'Okay', icon: '/img/icons/android-chrome-192x192.png'},
    //                 //     { action: 'cancel', title: 'Cancel', icon: '/img/icons/android-chrome-192x192.png'}
    //                 // ],
    //             }))
    //     }    
    // },
    initMembreCourant(context) {
        return new Promise((resolve, reject) => {
            if(context.rootState.auth.utilisateurCourant) {
                //const init_membre = context.rootState.auth.utilisateurCourant.membres_centre[0]
                const init_membre = context.rootState.auth.utilisateurCourant.membres_centre.find( membre => membre.estActif == true)
                context.commit('majMembreCourant', init_membre)
                resolve()
            }
            else {
                reject()
            }
        })
    },
    modifMembreCourant(context, payload) {
        const nouveau_membre = context.rootState.auth.utilisateurCourant.membres_centre.find( element => element.id == payload.id)
        context.commit('majMembreCourant', nouveau_membre)
    }
}

const mutations = {
    afficheSnackbar(state, payload){
        state.snackbar.titre = payload.titre
        state.snackbar.message = payload.message
        //state.snackbar.icone = payload.icone
        state.snackbar.type = payload.type
    },
    majMembreCourant(state, payload) {
        state.membre_courant = payload
    },
    majTitrePage(state, payload) {
        state.titre_page = payload
    }
}

const getters = {
    appVersion(state) {
        return state.packageVersion
    },
    membreCourant(state) {
        return state.membre_courant
    },
    titrePage(state) {
        return state.titre_page
    }
}

export default{
    state,
    actions,
    mutations,
    getters
}