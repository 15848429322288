//  Module Vuex de l'application
import axios from 'axios'

const state = {
    equipes: [],
    liste_centres: [],
    liste_departements: [],
}

const actions = {
    getEquipes(context, payload) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/centre/equipes/' + payload)
                .then((response) => {
                    
                    context.commit('majEquipes', response.data.equipes)
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    },
    getListeCentres(context) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/centre/liste')
                .then((response) => {
                    context.commit('majListeCentres', response.data.centres);
                   
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    },
    getListeDepartements(context) {
        return new Promise ((resolve, reject) => {
            axios.get('api/departement/liste')
                .then((response) => {
                    context.commit('majListeDepartements', response.data.departements);
                   
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    }
}

const mutations = {
    majEquipes(state, payload) {
        state.equipes = payload
    },
    majListeCentres(state, payload) {
        state.liste_centres = payload
    },
    majListeDepartements(state, payload) {
        state.liste_departements = payload
    }
}

const getters = {
    equipes_centre(state) {
        return state.equipes
    },
    liste_centres(state) {
        return state.liste_centres
    },
    liste_departements(state) {
        return state.liste_departements
    }
}

export default{
    state,
    actions,
    mutations,
    getters
}