import Vue from 'vue'
import router from './routes'
import VueRouter from 'vue-router'
import store from './vuex/store'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import Vuelidate from 'vuelidate'
import VueGapi from 'vue-gapi'

//	gestion des websockets
//import Echo from 'laravel-echo'
//window.Pusher = require('pusher-js')
//window.Pusher.logToConsole  = process.env.NODE_ENV !== 'production'

// window.Echo = new Echo({
// 	broadcaster: 'pusher',
// 	key: 'spplanning',
// 	wsHost: window.location.hostname,
// 	wsPort: 6001,
// 	disableStats: true,
// 	encrypted: false,
// 	enabledTransports: ['ws', 'wss'],	//..permet d'éviter de se connecter au service pusher officiel
// 	authEndpoint: 'api/broadcast/auth',
// })

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VueGapi, {
	apiKey: 'AIzaSyD7mqhd3Ry6c48nVXLBhNMCic4LZTAXSTs',
  	clientId: '613658533700-hcblrm58d6ldesikj1rj97d0q42ap66d.apps.googleusercontent.com',
  	discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
  	scope: 'https://www.googleapis.com/auth/calendar',
})


new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
