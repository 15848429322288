import VueRouter from 'vue-router'
import store from './vuex/store'
import {sync} from 'vuex-router-sync'
import axios from 'axios'

//  On change le titre de l'onglet selon si on est en prod ou en dev
document.title = process.env.NODE_ENV === 'production' ? 'SP Planning' : 'SP Planning (Version Dev.)'

//  Définition des routes gérées par Vue js
let routes = [
	{
		path: '/login',
		name: 'login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
		meta: {
			auth: false,	//	route seulement pour les non authentifiés,
			permissions: []
		}
	},
	// {
	// 	path: '/',
	// 	name: 'home',
	// 	component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
	// 	meta: {
	// 		auth: undefined	//	route publique
	// 	}
	// },
	{
		path: '/messages',
		name: 'messages',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "messages" */ './views/Messages.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: []
			//permissions: []
		}
	},
	{
		path: '/',
		name: 'compte',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "compte" */ './views/Compte.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: []
		}
	},
	{
		path: '/comptes_utilisateur',
		name: 'comptes_utilisateur',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "comptes_utilisateur" */ './views/ComptesSPV.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: ['acces gestion comptes']
		}
	},
	{
		path: '/equipes',
		name: 'equipes',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "equipes" */ './views/Equipes.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: ['acces gestion equipes']
		}
	},
	{
		path: '/presence_manoeuvres',
		name: 'presence_manoeuvres',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "presence_manoeuvres" */ './views/PresenceManoeuvre.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: ['acces presence manoeuvres']
		}
	},
	{
		path: '/suivi_fmpa',
		name: 'suivi_fmpa',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "suivi_fmpa" */ './views/SuiviFMPA.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: ['acces suivi fmpa']
		}
	},
	{
		path: '/gestion_fmpa',
		name: 'gestion_fmpa',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "gestion_fmpa" */ './views/GestionFMPA.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: ['acces gestion fmpa']
		}
	},
	{
		path: '/annuaire',
		name: 'annuaire',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "annuaire" */ './views/Annuaire.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: []
		}
	},
	{
		path: '/dispos_centre',
		name: 'dispos_centre',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dispos_centre" */ './views/DisposCentre.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: ['acces dispos centre']
		}
	},
	{
		path: '/test_admin',
		name: 'test_admin',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "test_admin" */ './views/TestAdmin.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés,
			permissions: ['acces test admin']
		}
	},
	{
		path: '/logs',
		name: 'Logs',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "logs" */ './views/Journal.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés,
			permissions: ['acces journal']
		}
	},
	{
		path: '/online',
		name: 'Online',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "online" */ './views/Online.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés,
			permissions: ['acces online']
		}
	},
	{
		path: '/astreintes_manoeuvres',
		name: 'astreintes_manoeuvres',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "astreintes_manoeuvres" */ './views/AstreintesManoeuvres.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés,
			permissions: ['acces gestion astreintes manoeuvres']
		}
	},
	{
		path: '/planning_spv',
		name: 'planning_spv',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "planning_spv" */ './views/PlanningSPV.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés,
			permissions: ['acces planning spv']
		}
	},
	{
		path: '/plannification_gardes',
		name: 'plannification_gardes',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "plannification_gardes" */ './views/PlannificationGardes.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés,
			permissions: ['acces plannification gardes']
		}
	},
	{
		path: '/statistiques',
		name: 'statistiques',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "statistiques" */ './views/Statistiques.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés,
			permissions: ['acces statistiques']
		}
	},
	// {
	// 	path: '/contact',
	// 	name: 'contact',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
	// 	meta: {
	// 		auth: true,	//	route seulement pour les authentifiés
	// 		permissions: []
	// 	}
	// },
	{
		path: '/maintenance',
		name: 'maintenance',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "maintenance" */ './views/Maintenance.vue'),
		meta: {
			auth: false,
			permissions: []
		}
	},
	{
		path: '/politique',
		name: 'politique',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "politique" */ './views/Politique.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés
			permissions: []
		}
	},
	{
		path: '/gestion_departement',
		name: 'gestion_departement',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "gestion_departement" */ './views/GestionDepartement.vue'),
		meta: {
			auth: true,	//	route seulement pour les authentifiés,
			permissions: ['acces gestion departement']
		}
	},
	{
        path:'*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
});

// sync Vuex and vue-router
sync(store, router)

//  vérification avant de naviguer vers une route
router.beforeEach((to, from, next) =>{
	const utilisateur_courant = store.getters.utilisateurCourant
	let membre_courant = store.getters.membreCourant
	const auth = to.matched.some(record => record.meta.auth)
	const permissions_requises = to.meta.permissions
	//console.log('membre courant: ', membre_courant)
	
	//  si besoin d'authentification et aucun utilisateur connecté
    if(auth && !utilisateur_courant){
        next('/login');
	}

	// si on voulait aller sur la page login alors qu'on est deja connecté
    else if(to.path=='/login' && utilisateur_courant){
        next('/');
	}
	


	//	si le lien requiert des permissions
	else if(utilisateur_courant && permissions_requises.length > 0) {
		// on vérifie si l'utilisateur a les droits pour accesder à la page
		//console.log(store.state.application.membre_courant)

		//	si le membre courant n'est pas encore chargé
		if(!membre_courant) {
			//	on prend le 1er par défaut
			membre_courant = store.getters.utilisateurCourant.membres_centre[0]
		}
		
		const roles_utilisateur = membre_courant ? membre_courant.roles : []  
		const permissions_utilisateur = membre_courant ? membre_courant.permissions : []
		const permissions_centre = membre_courant ? membre_courant.centre.parametres : []

		//console.log(roles_utilisateur)
		//const estSuperAdmin = roles_utilisateur.name.includes('Super admin')
		const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
		
		let permission_trouvee = false
		if(membre_courant) {
			//	si super admin on autorise tout
			if (estSuperAdmin) {
				permission_trouvee = true
			}
			else {
				//	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
				
				roles_utilisateur.forEach(role => {
					//  si des permissions sont rattachées au role
					role.permissions.forEach(permission => {
						if( permissions_requises.includes(permission.name) && permissions_centre.includes(permission.name) ) {
							permission_trouvee = true
						}
					})
				})
				
				// si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
				if (!permission_trouvee) {

					permissions_utilisateur.forEach(permission => {
						if( permissions_requises.includes(permission.name) && permissions_centre.includes(permission.name)) {
							permission_trouvee = true
						}
					})
					
				}
			}
			
		}
		
		//	si autorisé on va vers la page demandée
		//	on autorise si pas de permission ou si une permission et que la permission est trouvée
		if(permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)) {
			next()
		}
		else{
			//	sinon on redirige vers la page compte
			next('/compte')
		}
	}
	
	
	else{
        next();
    }
})

//	Modification des routes selon le retour du server
axios.interceptors.response.use(null, (error) => {
	//  retour à la page login si on reçoit une réponse 401 (non autorisé)
	if(error.response.status == 401){
		store.commit('logout');
		store.commit('deconnexionEcho')
		router.push('/login');
		store.commit('afficheSnackbar', {type: 'error', message: 'Opération interdite ou session expirée'});
	}
	
	//	si server en mode maintenance
	if(error.response.status == 503){
		router.push('/maintenance');
		//console.log(error.response)
	}

    //  les erreurs sont renvoyées dans le flux normal
    return Promise.reject(error);
})

//  ajout du header d'autorisation avec le token à toutes les requetes si un utilisateur est connecté
if(store.getters.utilisateurCourant){
	axios.defaults.headers.common["Authorization"] = `Bearer ${store.getters.utilisateurCourant.token}`
}

export default router;
