//  Module Vuex de l'application
import axios from 'axios'

const state = {
    annuaire: [],
    aptitudes: [],
    grades: [],
    permissions: [],
    roles: []
}

const actions = {
    getAnnuaire(context, payload) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/centre/annuaire/' + payload)
                .then((response) => {
                    
                    context.commit('majAnnuaire', response.data.annuaire)
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    },
    getAptitudes(context) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/centre/aptitudes')
                .then((response) => {
                    context.commit('majAptitudes', response.data.aptitudes);
                   
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    },
    getGrades(context) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/centre/grades')
                .then((response) => {
                    context.commit('majGrades', response.data.grades);
                   
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    },
    getPermissions(context, payload) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/centre/permissions/' + payload)
                .then((response) => {
                    
                    context.commit('majPermissions', response.data.permissions)
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    },
    getRoles(context, payload) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/centre/roles/' + payload)
                .then((response) => {
                    
                    context.commit('majRoles', response.data.roles)
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    }
    
}

const mutations = {
    majAnnuaire(state, payload) {
        state.annuaire = payload
    },
    majAptitudes(state, payload) {
        state.aptitudes = payload
    },
    majGrades(state, payload) {
        state.grades = payload
    },
    majPermissions(state, payload) {
        state.permissions = payload
    },
    majRoles(state, payload) {
        state.roles = payload
    }
}

const getters = {
    annuaire(state) {
        return state.annuaire
    },
    aptitudes(state) {
        return state.aptitudes
    },
    grades(state) {
        return state.grades
    },
    permissions(state) {
        return state.permissions
    },
    roles(state) {
        return state.roles
    }
}

export default{
    state,
    actions,
    mutations,
    getters
}