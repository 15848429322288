import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import router from './../../routes'

const state = {
    echo: null,
    users_online: [],
}

const actions = {
    //  opérations asynchrones
    effaceUser(context) {
        context.commit('logout', null, {root: true})
    }
}

const mutations = {
    
    //  Connexion au websocket
    connexionEcho(state, payload) {
        //  payload est un objet contenant token et id
        if(!state.echo) {
            state.echo = new Echo({
                broadcaster: 'pusher',
                key: '6512bd14cdff4c67ba5e', //'spplanning',
                forceTLS: true,
                cluster: 'eu',
                //wsHost: process.env.NODE_ENV == 'production' ? 'socket.spplanning.fr' :window.location.hostname,
                //wsPort: 6001,
                //wssPort: 6001,
                //wsPort: process.env.NODE_ENV == 'production' ? 80 : 6001,
                //wssPort: process.env.NODE_ENV == 'production' ? 443 : 6001,
                //forceTLS: process.env.NODE_ENV === 'production',
                disableStats: true,
                //enabledTransports: ['ws', 'wss'],	//..permet d'éviter de se connecter au service pusher officiel
                //enabledTransports: ['ws'],
                authEndpoint: 'api/broadcast/auth',
                auth: {
                    headers: {
                        Authorization: `Bearer ${payload.token}`
                    }
                }
            })
        }

        //  Abonnement au canal privé de réception
        state.echo.private('App.User.'+ payload.id)
            .notification((notification) => {
                this.dispatch('getMessagesNonLus', payload.id ,{root: true})
                
                //  si on recoit une notif de deconnexion, on déconnecte l'utilisateur
                if(notification.type == 'App\\Notifications\\NotificationDeconnexion') {
                    this.dispatch('effaceUser')
                    router.push('/login')
                }
            })
            
            //  à réception d'un message 'Test'
            // .listen('Test', (data) => {
            //     console.log('message privé reçu')
            //     console.log('donnees recues:' ,data)
            //     if (! ('Notification' in window)) {
            //         alert('Les notifications Web ne sont pas supportées');
            //         return
            //     }

            //     Notification.requestPermission( permission => {
            //         let notification = new Notification('Nouveau message!', {
            //             body: data.donnees.titre,
            //             //body: post.title, // content for the alert
            //             icon: '/img/icons/android-chrome-192x192.png',
            //             vibrate: [300, 200, 300],
		    //             badge: '/img/icons/android-chrome-96x96.png',
            //         })
      
            //         // link to page on clicking the notification
            //         notification.onclick = () => {
            //           window.open(window.location.href);
            //         }
            //     })
                
            // })

        

        
    },

    //  connexion au canal online
    connexionOnline(state, payload) {
        if(state.echo) {
            state.echo.join('online')
                .here(utilisateurs => {
                    state.users_online = utilisateurs
                })
                .joining( utilisateur => {
                    state.users_online.push(utilisateur)
                })
                .leaving(utilisateur => {
                    state.users_online = state.users_online.filter(user => user.id != utilisateur.id)
                })
        }
    },

    //  Deconnexion du Websocket
    deconnexionEcho(state, payload) {
        if(state.echo) state.echo.connector.pusher.connection.disconnect()
        state.echo = null
    }
}

const getters = {
    estConnecte(state){
        return state.echo && state.echo.connector.pusher.connection.connection !== null
    },
    instanceEcho(state) {
        return state.echo
    },
    usersOnline(state) {
        return state.users_online
    }
}

export default{
    state,
    actions,
    mutations,
    getters
}