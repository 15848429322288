import axios from 'axios'

const utilisateur = !localStorage.getItem('utilisateur') ? null : JSON.parse(localStorage.getItem('utilisateur'))

const state = {
    donnees_compte: [],
    utilisateurCourant: utilisateur,
    isLoggedIn: !!utilisateur,  //  !! sert à caster la réponse en boolée
    loading: false,
    auth_error: null,
    pass_a_modifier: false
}

const getters = {
    donneesCompte(state) {
        return state.donnees_compte
    },
    utilisateurCourant(state){
        return state.utilisateurCourant
    },
    isLoggedIn(state){
        return state.isLoggedIn
    },
    isLoading(state){
        return state.loading
    },
    auth_error(state){
        return state.auth_error
    },
    passAModifier(state) {
        return state.pass_a_modifier
    }
}

const actions = {
    getDonneesCompte(context) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/user/compte')
                .then((response) => {
                    context.commit('majDonneesCompte', response.data.resultat);
                
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    },
    login(context){
        context.commit("login")
    },
    loginReq(context, formulaire) {
        return new Promise((resolve, reject) => {
            axios.post('api/auth/login', formulaire)
                .then((reponse) => {
                    axios.defaults.headers.common["Authorization"] = `Bearer ${reponse.data.access_token}`
                                                                         
                    context.commit('LOGIN_SUCCESS', reponse)
    
                    //  Connexion au Websocket
                    context.commit('connexionEcho', { token: reponse.data.access_token, id: reponse.data.user.id}, { root: true })

                    //  on attribue comme profil membre le 1er de la liste actif
                    context.dispatch('initMembreCourant', null, { root: true });
                    resolve(reponse.data);
                })
                .catch((error) => {
                    console.log(error)
                    context.commit('LOGIN_FAILED', error)
                    reject(error)
                })
        })
    }
}

const mutations = {
    login(state){
        state.loading = true
        state.auth_error = null
    },
    LOGIN_SUCCESS (state, payload){
        state.auth_error = null
        state.isLoggedIn = true
        state.loading = false

        // on vérifie si l'utilisateur est administrateur (droit id=3)
        //let estAdmin = !!payload.user.droits.find(x => x.id == 3);      //  on cherche si id = 3 existe et on convertie le résultat en booléen
        //let estResponsableUnite = !!payload.user.droits.find(x => x.id == 2);
        
        //  insertion du token dans l'objet user: on crée un nouvel objet pour le faire
        state.utilisateurCourant = Object.assign(
            {},     //  objet vide
            payload.data.user, //  ajout de l'objet user  
            {   
                token: payload.data.access_token,    // ajout d'une propriété token dans l'objet user
                //contrats: payload.contrats,
                //estAdmin: estAdmin,
                //estResponsableUnite: estResponsableUnite
            }   
        );

        //  si le mot de passe est celui par defaut on demande de le changer
        state.utilisateurCourant.passwordTemporaire ? state.pass_a_modifier = true : state.pass_a_modifier = false

        //  enregistrement de l'utilisateur en local storage
        localStorage.setItem("utilisateur", JSON.stringify(state.utilisateurCourant));
       

    },
    LOGIN_FAILED (state, payload){
        state.loading = false
        state.auth_error = payload.error
    },
    logout(state){
        localStorage.removeItem("utilisateur")
        state.isLoggedIn = false
        state.utilisateurCourant = null
    },
    LOGOUT_SUCCESS (state){
        //state.moi = null
    },
    majDonneesCompte(state, payload) {
        state.donnees_compte = payload
    },
    passParDefaut(state, payload) {
        //state.pass_a_modifier = payload
        state.utilisateurCourant.passwordTemporaire = payload

        //  mise à jour dans le localStorage en cas de rechargement de la page
        localStorage.setItem("utilisateur", JSON.stringify(state.utilisateurCourant));
    }
}

export default{
    state,
    actions,
    mutations,
    getters
}