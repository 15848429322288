//  Module Vuex de l'application
import axios from 'axios'

const state = {
    categories_fmpa: []
}

const actions = {
    getCategoriesFMPA(context, payload) {
        // création d'une Promise
        return new Promise ((resolve, reject) => {
            axios.get('api/fmpas/categories')
                .then((response) => {
                    
                    context.commit('majCategoriesFMPA', response.data.categories)
                    // si tout s"est bien passé, on renvoie au demandeur le résultat
                    resolve(response);
                })
                .catch((error) => {
                    
                    // si erreur on renvoie au demandeur l'erreur
                    reject(error);
                })

        })
    }
}

const mutations = {
    majCategoriesFMPA(state, payload) {
        state.categories_fmpa = payload
    }
}

const getters = {
    categoriesFMPA(state) {
        return state.categories_fmpa
    }
}

export default{
    state,
    actions,
    mutations,
    getters
}